import { State as RootState } from '@/store/state'
import { ActionTree } from 'vuex'

import { Datatable } from './state'
import { fetchData } from '@/graphql/queries/resources/datatables'
import { fetchAggregates } from '@/graphql/queries/aggregates'
import { AggregateType } from '@/entities/public/Resource/interfaces'
import { versionYearAggregates } from '@/graphql/customQueries/vehicle/versionYear'
import { sendMileagePrice } from '@/graphql/customQueries/vehicle/mileagePrice'

export const actions: ActionTree<Datatable, RootState> = {
  fetchData: async ({ commit, rootState: { apolloClient } }, { resource, period, filter, cursor, force = true }) => {
    if (!apolloClient) throw new Error(`Can't fetch datatable data yet`)
    if (!resource?.query) return
    commit('setData', { total: 0, records: [] })
    try {
      const { query } = resource
      commit('setData', await fetchData(apolloClient, query, period, filter, cursor, force))
    } catch (e) {
      console.error(e)
      commit('setData', { total: 0, records: [] })
    }
  },
  fetchAggregates: async ({ commit, rootState: { apolloClient } }, {
    aggregate,
    period,
    filter,
  }): Promise<Array<AggregateType>> => {
    if (!apolloClient) throw new Error(`Can't fetch aggregate data yet`)

    try {
      return await fetchAggregates(apolloClient, aggregate, period, filter)
    } catch (e) {
      console.error(e)
      return []
    }
  },
  refresh: async ({ dispatch }, payload) => {
    await dispatch('fetchData', payload)
  },
  findVersionYearAggregates: async ({ rootState: { apolloClient } }, { aggregate, order }): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)

    try {
      const {
        data,
      } = await apolloClient.query({
        query: versionYearAggregates,
        variables: {
          aggregate,
          order,
        },
      })

      return data
    } catch (e) {
      return null
    }
  },
  updateMileagePrice: async ({ rootState: { apolloClient } }, input): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)

    try {
      const {
        data: { data },
      } = await apolloClient.mutate({
        mutation: sendMileagePrice,
        variables: {
          input,
        },
      })

      return data
    } catch (e) {
      return null
    }
  },
}
