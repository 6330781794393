import gql from 'graphql-tag'

export const sumCategoryScore = gql`
  query sumCategoryScore($categoryName: String!, $id_inspection: Int!) {
    response:purchase_inspection_assessment_aggregate(where: {inspection_qualifications: {component: {_and: {id_inspection: {_eq: $id_inspection}, inspection_component: {component: {category: {name: {_eq: $categoryName}}}}}}}}) {
      aggregate {
        sum {
          score
        }
      }
    }
  }
`
