import { Type } from 'class-transformer'
import { Entity } from '..'

import { Negotiation } from '.'
import { ClosingReason, ProcessStatus, SupportDocumentType, TransferType } from '../settings'
import { Employee } from '../hr'
import { fixPrice } from '@/utils/general'
import { Payment, PaymentOrder } from '@/entities/finance'
import { Deal } from '@/entities/crm'

const enum IndicatorColor {
  blue = 'blue',
  green = 'green'
}

export class PurchaseOrder extends Entity {
  @Type(() => TransferType)
  transferType: TransferType;

  @Type(() => SupportDocumentType)
  supportDocumentType: SupportDocumentType;

  @Type(() => Negotiation)
  negotiation: Negotiation;

  @Type(() => ProcessStatus)
  status: ProcessStatus;

  @Type(() => Employee)
  validator: Employee;

  @Type(() => ClosingReason)
  closingReason: ClosingReason

  agreedAmount: number;
  paymentOrder: PaymentOrder
  dealSale: Deal
  payments: Payment[]

  get amount () {
    return fixPrice(this.agreedAmount)
  }

  get price () {
    const {
      negotiation: {
        negotiationResponseAmount: { value: authorizePrice },
      },
      agreedAmount,
    } = this

    let color = IndicatorColor.green
    let tooltip = 'Mismo precio autorizado'

    if (authorizePrice > agreedAmount) {
      color = IndicatorColor.blue
      tooltip = 'Menos precio del autorizado'
    }

    return { value: agreedAmount, indicator: { background: color, icon: 'mdi-currency-usd', color: undefined, tooltip } }
  }

  get authorizePrice () {
    const { negotiation } = this
    return fixPrice(negotiation?.lastResponse?.amount)
  }

  get marginPositive () {
    const { negotiation, agreedAmount } = this

    return fixPrice(negotiation?.lastResponse?.amount - agreedAmount)
  }

  get client () {
    const { negotiation } = this

    return negotiation?.client
  }
}
