import gql from 'graphql-tag'
import { EntityFields, EnumFields } from '..'

export const ResourceGroupFragment = gql`
  fragment ResourceGroup on resource_group {
    ${EntityFields}
    ${EnumFields}
    title
    icon
    path
  }
`
