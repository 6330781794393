import gql from 'graphql-tag'
import { EntityFields } from '..'
import { PaymentOrderItemFragment } from './paymentOrderItem'
import { DealFragment } from '@/graphql/fragments/crm'
import { ProcessStatusFragment } from '@/graphql/fragments/settings'

export const BasePaymentOrderFragment = gql`
  fragment BasePaymentOrder on finance_payment_order{
    ${EntityFields}
    idProcessRecord:id_process_record
  }
`

export const PaymentOrderFragment = gql`
  ${BasePaymentOrderFragment}
  ${PaymentOrderItemFragment}
  ${DealFragment}
  ${ProcessStatusFragment}
  fragment PaymentOrder on finance_payment_order{
    ...BasePaymentOrder
    items{
      ...PaymentOrderItem
    }
    status:process_status{
      ...ProcessStatus
    }
  }
`
