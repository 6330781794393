import gql from 'graphql-tag'
import { EntityFields } from '..'
import { BaseFieldFragment } from './field'

export const BaseDocumentTypeFragment = gql`
  fragment BaseDocumentType on documents_document_type{
    ${EntityFields}
  }
`

export const DocumentTypeFragment = gql`
  ${BaseDocumentTypeFragment}
  ${BaseFieldFragment}
  fragment DocumentType on documents_document_type{
    ...BaseDocumentType
    fields{
      ...BaseField
    }
  }
`
