import { Type } from 'class-transformer'
import { Location } from 'vue-router/types/router'

import { Entity } from '../..'
import { ResourceType, ResourceGroup } from '..'
import { Cursor, Filter, Query } from './interfaces'
import { Link } from '@/store/app/state'
import { Metadata, Form, View, ToolKit, CustomView } from './metadata'
import { initArray } from '@/utils/general'
import { MissingAPI } from '@/errors'

export const defaultCursor: Cursor = { page: 1, size: 20 }

export class Resource extends Entity {
  @Type(() => ResourceGroup)
  group?: ResourceGroup;

  // hasura does not allow modified the field name to type
  @Type(() => ResourceType)
  resourceType: ResourceType;

  @Type(type => Metadata,
    {
      keepDiscriminatorProperty: true,
      discriminator: {
        property: '__type',
        subTypes: [
          { value: View, name: ResourceType.view },
          { value: Form, name: ResourceType.form },
          { value: ToolKit, name: ResourceType.toolkit },
          { value: CustomView, name: ResourceType.customView },
        ],
      },
    }
  )
  metadata: View | Form | ToolKit | CustomView;

  name: string;
  description: string;
  slug: string;
  icon: string;
  order?: number;

  cursors: Record<string, Cursor> = { main: { ...defaultCursor } }

  get path (): string {
    const { group, slug } = this
    const path = [group?.path, group?.name, slug].filter(val => val)

    return path.join('/').trim()
  }

  get route (): Location {
    const { group, slug, path, resourceType } = this

    switch (resourceType.name) {
      case ResourceType.form:
        return {
          name: 'model_form',
          params: { model: this.metadata.model },
          query: { form: slug },
        }
      case ResourceType.view:
        return {
          name: group?.name || slug,
          path,
        }
      case ResourceType.customView:
        return {
          name: group?.name,
          path,
        }
    }
  }

  get link (): Link {
    const { id, name, route, icon } = this

    return {
      id,
      name,
      route,
      icon,
    }
  }

  get parent (): Link | undefined {
    const { group, route } = this
    if (!group) return

    const { id, title: name, icon } = group
    return { id, name, icon, route }
  }

  // TODO: Remove this getter | Use the one in the corresponding class
  get query (): Query {
    const { metadata } = this
    if (metadata instanceof View) return metadata.query
  }

  // TODO: Remove this getter | Use the one in the corresponding class
  get filters (): Array<Filter> | undefined {
    const { metadata } = this
    if (metadata instanceof View) return metadata.filters
  }

  get isView (): boolean {
    const { resourceType: { name } } = this
    return name === ResourceType.view || name === ResourceType.customView
  }

  get isForm (): boolean {
    const { resourceType: { name } } = this
    return name === ResourceType.form
  }

  get queries () {
    const { metadata } = this
    if (!(metadata instanceof ToolKit) && !(metadata instanceof Form)) return undefined

    const { api } = metadata
    if (!api) throw new MissingAPI()

    return api.queries
  }

  cursor (filter?: Filter) {
    const { cursors } = this
    if (!filter?.name) return cursors.main

    const cursor = cursors[filter.name]
    if (!cursor) return cursors[filter.name] = { ...defaultCursor }

    return cursor
  }

  refresh () {
    const { cursors } = this

    Object.entries(cursors).forEach(([name, cursor]) => {
      if ('refresh' in cursor) return cursor.refresh = cursor.page

      Object.defineProperty(cursor, 'refresh', (() => {
        let pages = initArray(cursor.size, true)
        return {
          get () {
            const i = cursor.page - 1
            return pages[i]
          },
          set (value) {
            if (typeof value === 'number') return pages = initArray(cursor.size, true)

            const i = cursor.page - 1
            pages[i] = value
          },
        }
      })())
    })
  }
}
