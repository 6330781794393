import { GetterTree, MutationTree } from 'vuex'

import { State as RootState } from '@/store/state'
import { ResourceType } from '@/entities/public/Resource/interfaces'

export interface ResourceData {
  total: number
  records: Array<ResourceType>
}

export interface Datatable {
  data: ResourceData
}

export function initState (): Datatable {
  return {
    data: {
      total: 0,
      records: [],
    },
  }
}

export const mutations: MutationTree<Datatable> = {
  setData: (state, data) => state.data = data,
}

export const getters: GetterTree<Datatable, RootState> = {
  data: ({ data }) => data,
}
