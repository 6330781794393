import gql from 'graphql-tag'

import { InspectionFragment } from '@/graphql/fragments/purchase'

export const query = gql`
  ${InspectionFragment}
  query findInspection($filter: purchase_inspection_bool_exp){
    records: purchase_inspection(where: $filter) {
      ...Inspection
    }
  }
`

export default query
