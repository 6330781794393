import { Type } from 'class-transformer'
import { Entity } from '..'
import { DocumentType, FieldType, Intervener } from '.'

export class Field extends Entity {
  @Type(() => DocumentType)
  documentType: DocumentType;

  @Type(() => FieldType)
  process: FieldType;

  @Type(() => Intervener)
  interveners: Intervener[];

  name: string
}
