import { Type } from 'class-transformer'
import { EnumEntity } from '..'

import { NegotiationResponse } from '.'
import { NegotiationResponseTypeEnum } from '@/graphql'

export class NegotiationResponseType extends EnumEntity {
  public static readonly offer: NegotiationResponseTypeEnum = 'offer'
  public static readonly appealed: NegotiationResponseTypeEnum = 'appealed'

  @Type(() => NegotiationResponse)
  negotiationResponses: NegotiationResponse[];

  declare name: NegotiationResponseTypeEnum
}
