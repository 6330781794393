import gql from 'graphql-tag'
import { ClosingReasonFragment, ProcessStatusFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${ProcessStatusFragment}
  ${ClosingReasonFragment}
  query findEvaluation($filter: loans_evaluation_bool_exp!,$order: [loans_evaluation_order_by!], $limit: Int){
    records: loans_evaluation(where: $filter, limit: $limit, order_by: $order) {
      id
      status{
        ...ProcessStatus
      }
      closingReason:closing_reason {
        ...ClosingReason
      }
    }
  }
`

export default query
