import { Type } from 'class-transformer'
import { Entity } from '..'
import { VersionYear } from '@/entities/vehicle/VersionYear'
import { Icon } from '@/entities/crm'

export class RotationIndex extends Entity {
  @Type(() => VersionYear)
  versionYear: VersionYear;

  @Type(() => Icon)
  icon: Icon

  name: string;
  description: string;
  value: number;
  color: string;
  marketPricePercentage: number;

  get percentage () {
    const { marketPricePercentage } = this

    return `${marketPricePercentage * 100}%`
  }

  get fieldDescription () {
    const { value, percentage, description } = this

    return `${value} / ${percentage} / ${description}`
  }
}
