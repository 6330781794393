import { Type } from 'class-transformer'
import { Entity } from '..'

import { AutoOwner, Car, Country, DealAutoAttribute, Maintenance, Ownership } from '.'
import { ProcessStatus } from '../settings'
import { Attribute, Generation, VersionYear } from '../vehicle'
import { Deal } from '../crm'

export class Auto extends Entity {
  @Type(() => VersionYear)
  version: VersionYear;

  @Type(() => Country)
  country: Country;

  @Type(() => ProcessStatus)
  status: ProcessStatus;

  @Type(() => AutoOwner)
  owners: AutoOwner[];

  @Type(() => Deal)
  deals: Deal[];

  @Type(() => Ownership)
  ownerships: Ownership[]

  @Type(() => Maintenance)
  maintenances: Maintenance[]

  @Type(() => Generation)
  generation: Generation;

  @Type(() => Attribute)
  attributes: Attribute[];

  @Type(() => Car)
  car: Car;

  // Necessary for wing component auto
  dealAutoAttribute?: DealAutoAttribute[];
  registrationPlate?: string;

  lastPrice?: number;

  get ppu () {
    const { registrationPlate } = this
    return registrationPlate
  }

  get name () {
    const { version, ppu } = this
    let name = version.title

    if (ppu) {
      name += ' ' + ppu
    }

    return name
  }

  get alertName () {
    const { version } = this

    return `${version.title}`
  }

  get year () {
    const { version: { year } } = this
    return Number(year)
  }

  get price (): number {
    const { lastPrice } = this
    return lastPrice
  }

  get date () {
    // TODO: Return date from reserved/selling/sold
    const date = undefined
    return this.formatDate(date, 'DD/MM/YYYY')
  }

  get isAvailable () {
    // TODO: Compute current status
    return true
  }

  get formName () {
    const { version: { version: { model }, year }, ppu } = this

    return `${model.title} ${year} ${ppu || ''} `
  }

  get nameWithOutPPU () {
    const { version: { title } } = this

    return `${title}`
  }

  get title () {
    const { version: { version: { model } } } = this
    return `${model.title}`
  }

  get autoStatus () {
    return this
  }

  get pie () {
    const { lastPrice } = this
    if (!lastPrice) return
    return (lastPrice * 0.4)
  }

  get officialSite () {
    const { version: { version: { model: { officialWeb: modelWeb, brand: { officialWeb } } } } } = this

    return modelWeb || officialWeb
  }

  get characteristic () {
    const { version, generation } = this

    return generation?.characteristic || version?.characteristic
  }

  get idGeneration () {
    const { generation } = this
    return generation?.id
  }

  get idVersionYear () {
    const { version } = this
    return version?.id
  }
}
