import { Type } from 'class-transformer'
import { EnumEntity } from '..'

import { AppraisalResponse } from '.'
import { AppraisalResponseTypeEnum } from '@/graphql'

export class AppraisalResponseType extends EnumEntity {
  public static readonly offer: AppraisalResponseTypeEnum = 'expected_offer'
  public static readonly appraised: AppraisalResponseTypeEnum = 'appraised'
  public static readonly appealed: AppraisalResponseTypeEnum = 'appealed'

  @Type(() => AppraisalResponse)
  responses: AppraisalResponse[]

  declare name: AppraisalResponseTypeEnum
}
