import { Type } from 'class-transformer'
import { Entity } from '..'
import { Inspection, NegotiationResponse, PurchaseOrder } from '.'
import { ClosingReason, ProcessStatus } from '../settings'

export class Negotiation extends Entity {
  @Type(() => Inspection)
  inspection: Inspection;

  @Type(() => ProcessStatus)
  status: ProcessStatus;

  @Type(() => NegotiationResponse)
  responses: NegotiationResponse[];

  @Type(() => PurchaseOrder)
  purchaseOrder: PurchaseOrder;

  @Type(() => ClosingReason)
  closingReason: ClosingReason

  cavValidation: Record<string, any> | null
  legalReportQualification: number
  agreedAmount: number
  taxAssessment: number

  get response () {
    const { responses } = this
    return responses && responses[responses.length - 1]
  }

  get lastResponse () {
    const { responses } = this
    return responses && responses[0]
  }

  get auto () {
    const { inspection: { appraisal: { deal: { auto } } } } = this

    return auto
  }

  get pipeline () {
    const { inspection: { appraisal: { deal: { lead: { pipeline } } } } } = this

    return pipeline
  }

  get client () {
    const { inspection: { appraisal: { deal: { lead: { client } } } } } = this
    return client
  }

  get executive () {
    const { inspection: { appraisal: { deal: { lead: { executive } } } } } = this
    return executive
  }

  get appraisalPrice () {
    const { inspection: { appraisal: { price: value } } } = this
    return { value }
  }

  get inspectionCost () {
    const { inspection: { metadata } } = this
    return { value: metadata?.totalAmount }
  }

  get authorizedAmount () {
    const { inspection: { metadata } } = this

    return { value: metadata?.authorizedAmount }
  }

  get inspectionAmount () {
    const { inspectionCost, authorizedAmount } = this

    const value = authorizedAmount?.value ? authorizedAmount?.value : inspectionCost?.value
    return { value }
  }

  get offerPostInspection () {
    const { inspection, inspectionAmount } = this
    if (!inspection) return 0

    const { appraisal: { agreedAmount, agreedAmountDifferent } } = inspection

    if (agreedAmount && agreedAmountDifferent) {
      return agreedAmount - inspectionAmount.value
    }
    return 0
  }

  get authorizePostInspection () {
    const { inspection, inspectionAmount } = this
    if (!inspection) return 0

    const { appraisal: { lastAppraisalResponse } } = inspection

    if (lastAppraisalResponse?.value) {
      return lastAppraisalResponse.value - inspectionAmount.value
    }
    return 0
  }

  get negotiationResponseAmount () {
    const { responses, authorizePostInspection } = this

    const response = responses?.find(resp => resp.isOffer)
    if (!response) return { value: 0 }

    if (authorizePostInspection !== response.amount) return { value: response.amount }

    return { value: 0 }
  }

  get negotiationResponse () {
    const { responses } = this

    const response = responses?.find(resp => resp.isOffer)

    if (!response) return { value: 0 }

    return { value: response.amount }
  }

  get negotiationResponseAppealed () {
    const { responses } = this

    const response = responses?.find(resp => resp.isAppealed)
    if (!response) return { value: 0 }

    return { value: response.amount }
  }

  get responseAppealed () {
    const { negotiationResponseAppealed } = this

    return negotiationResponseAppealed?.value
  }

  get agreementPrice () {
    const { inspection: { appraisal: { deal: { negotiationAmount } } } } = this

    return { value: negotiationAmount }
  }

  get rating () {
    const { legalReportQualification } = this

    return legalReportQualification || 0
  }

  get buttonPurchase () {
    const { id, status } = this

    return {
      disabled: !status.isApproved,
      parent: { id, model: 'Negotiation' },
      constructor: { name: 'PurchaseOrder' },
    }
  }

  get alert () {
    const { status } = this
    if (!status) return null

    if (status?.isToUpdating || status?.isPending || status?.isApproved || status?.isRejected) {
      return {
        icon: status.icon,
        color: '#FFFFFF',
        tooltip: status.description,
        background: status.color,
      }
    }

    return undefined
  }
}
