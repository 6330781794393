import { MutationTree } from 'vuex'
import { Dashboard } from '@/store/dashboards/state'

export const mutations: MutationTree<Dashboard> = {
  setLeadsSell (state, sells) {
    state.leadsSell = sells
  },
  setSells (state, sells) {
    state.sells = sells
  },
  setLeadsBuy (state, leadBuys) {
    state.leadsBuy = leadBuys
  },
  setBuys (state, buys) {
    state.buys = buys
  },
  setOptions (state, options) {
    state.options = options
  },
  setData (state, data) {
    state.data = data
  },
}
