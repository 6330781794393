
  import Vue from 'vue'
  import UpdatePwa from '@/components/updatePwa.vue'
  import GlobalNotification from '@/components/global/GlobalNotification.vue'
  import { Component, Watch } from 'vue-property-decorator'
  import { mapActions, mapMutations } from 'vuex'
  import NetworkDetector from '@/components/NetworkDetector.vue'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { formFilter } from '@/graphql/generated-types'
  import { Debounce } from '@/utils/decorators'

@Component({
  components: { UpdatePwa, GlobalNotification, NetworkDetector },
  methods: {
    ...mapActions('app', ['setMobile', 'setIsResize']),
    ...mapMutations('app', ['setSystem']),
    ...mapActions('resources/form', ['fetchData']),
  },
})
  export default class App extends Vue {
  setMobile!: (payload: boolean) => void
  setIsResize!: (payload: number) => void
  setSystem!: (payload: string) => void

  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  async mounted () {
    await this.getSystem()
  }

  @Debounce()
  async getSystem () {
    const system = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { type: { name: { _eq: 'system' } } },
    })

    this.setSystem(system?.[0]?.alias)
  }

  @Watch('$vuetify.breakpoint', { immediate: true, deep: true }) onBreakpointChange (val) {
    // if this width changes, update resourceView DT mobile breakpoint
    this.setMobile(val.width < 875)
  }

  @Watch('$vuetify.breakpoint.width', { immediate: true, deep: true }) onWidthChange (val) {
    this.setIsResize(val)
  }

  @Watch('$vuetify.breakpoint.height', { immediate: true, deep: true }) onHeightChange (val) {
    this.setIsResize(val)
  }
  }
