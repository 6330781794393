import { Type } from 'class-transformer'
import { Entity } from '..'

import { Resource } from '.'

export class ResourceGroup extends Entity {
  @Type(() => Resource)
  resources: Resource[];

  name: string;
  description: string;
  title: string;
  icon: string;
  path?: string
}
