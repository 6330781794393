import { GetterTree } from 'vuex'
import { State as RootState } from '@/store/state'
import { Details } from '@/utils/generalInterface'

export interface Dashboard {
  leadsSell: Array<number>
  leadsBuy: Array<number>
  sells: Array<number>
  buys: Array<number>
  options: Array<string>
  data: Details
}

export function initState (): Dashboard {
  return {
    leadsSell: [],
    leadsBuy: [],
    sells: [],
    buys: [],
    options: [],
    data: {} as Details,
  }
}

export const getters: GetterTree<Dashboard, RootState> = {
  leadSells: (state: Dashboard) => state.leadsSell,
  leadBuys: (state: Dashboard) => state.leadsBuy,
  sells: (state: Dashboard) => state.sells,
  buys: (state: Dashboard) => state.buys,
  options: (state: Dashboard) => state.options,
  data: (state: Dashboard) => state.data,
}
