import { Type } from 'class-transformer'
import { Entity } from '..'

import { AccountType, Person, PersonAccount } from '.'

export class BankData extends Entity {
  @Type(() => Person)
  bank: Person;

  @Type(() => Person)
  holder: Person;

  @Type(() => AccountType)
  accountType: AccountType;

  @Type(() => PersonAccount)
  holders?: PersonAccount[];

  accountNumber: string;

  get name () {
    const { bank, accountNumber, accountType } = this

    return `${accountNumber}/${bank?.alias}/${accountType?.shortName}`
  }
}
