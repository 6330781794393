import { Type } from 'class-transformer'
import { Entity } from '..'

import { AppraisalDetail, AppraisalExternalOffer, AppraisalMarketPrice, AppraisalResponse, Inspection } from '.'
import { Deal } from '../crm'
import { ClosingReason, ProcessStatus } from '../settings'
import { Employee } from '../hr'
import { AppraisalStatusAlert } from '@/models/interfaces'
import { Helpers } from '@/views/datatables/resources/helpers'
import { fixPrice } from '@/utils/general'

export class Agreement {
  response: AppraisalResponse;
  request: AppraisalResponse;

  constructor (response: AppraisalResponse, request: AppraisalResponse) {
    this.response = response
    this.request = request
  }

  get difference (): number {
    const { response, request } = this
    if (!response || !request) return undefined

    return request.amount - response.amount
  }

  get value () {
    const { difference } = this
    return difference
  }

  get ratio (): number {
    const { response, request } = this
    if (!response || !request) return undefined

    return 1 - request.amount / response.amount
  }

  get hasDifference (): boolean {
    const { difference } = this
    return Boolean(difference)
  }
}

export class Appraisal extends Entity {
  @Type(() => Deal)
  deal: Deal;

  @Type(() => Employee)
  appraiser: Employee;

  @Type(() => ProcessStatus)
  processStatus: ProcessStatus;

  @Type(() => ClosingReason)
  closingReason: ClosingReason;

  @Type(() => AppraisalExternalOffer)
  externalOffers: AppraisalExternalOffer[];

  @Type(() => AppraisalMarketPrice)
  marketPrices: AppraisalMarketPrice[];

  @Type(() => AppraisalResponse)
  responses: AppraisalResponse[];

  @Type(() => Inspection)
  inspection: Inspection;

  @Type(() => AppraisalDetail)
  detail: AppraisalDetail;

  agreedAmount: number | null
  expectedPublicationAmount: number | null
  cavValidation: Record<string, any> | null
  responsesAggregate: Record<string, any> | null

  private _appraisals: AppraisalResponse[];
  private _clientResponses: AppraisalResponse[];
  private _agreement: Agreement;

  get appraisalAlert () {
    const { processStatus, detail } = this
    if (!processStatus?.isNotOffer && !processStatus?.isPending) {
      return undefined
    }

    if (!detail?.id) {
      return [{ background: 'orange', icon: 'mdi-magnify', color: undefined, tooltip: 'Generando análisis' }]
    }

    return [{ background: 'green', icon: 'mdi-magnify', color: undefined, tooltip: 'Análisis completado' }]
  }

  get response () {
    const { responses } = this
    return responses && responses[0]
  }

  get active () {
    const { closingReason } = this
    return !closingReason
  }

  get appraisals () {
    // TODO: Verify on subscription change if it recomputes the property.
    const { _appraisals } = this
    if (!_appraisals) return this._appraisals = this.responses?.filter(({ isAppraised }) => isAppraised)

    return _appraisals
  }

  get clientResponses () {
    // TODO: Verify on subscription change if it recomputes the property.
    const { _clientResponses } = this
    if (!_clientResponses) return this._clientResponses = this.responses?.filter(({ isAppraised }) => !isAppraised)

    return _clientResponses
  }

  get appraisal () {
    const { appraisals } = this

    return appraisals && appraisals[0]
  }

  get appeal () {
    const { clientResponses } = this
    return clientResponses && clientResponses[0]
  }

  get agreement (): Agreement {
    // TODO: Verify on subscription change if it recomputes the property.
    const { _agreement } = this
    if (_agreement) return _agreement

    const { appraisal, appeal } = this
    return this._agreement = new Agreement(appraisal, appeal)
  }

  get status () {
    const { processStatus } = this
    return processStatus
  }

  get price (): number {
    const { appraisal } = this
    return appraisal ? appraisal.amount : 0
  }

  get client () {
    const { deal } = this

    if (!deal) return null
    const { lead } = deal

    if (!lead) return null

    const { client } = lead

    return client
  }

  get pipeline () {
    const { deal } = this

    if (!deal) return null
    const { lead } = deal

    if (!lead) return null

    const { pipeline } = lead

    return pipeline
  }

  get market () {
    const { marketPrices } = this

    return marketPrices && marketPrices[0]
  }

  get bestOffer () {
    const { externalOffers, price } = this

    const offers: Array<any> = [...externalOffers]
    // TODO: Replace `PETERSEN` with the system default company alias
    if (price) offers.push({ name: 'PETERSEN', value: price })

    if (!offers.length) return []

    const bestOffer = offers[0].value > price ? offers[0] : offers[offers.length - 1]
    Object.assign(bestOffer, {
      icon: undefined,
      color: 'primary',
    })

    return offers.sort((a, b) => b.value - a.value)
  }

  get alert (): AppraisalStatusAlert {
    const { active, closingReason } = this
    if (!active && closingReason.isExpired) return AppraisalStatusAlert.expired

    const { agreement } = this

    if (!active || !agreement.response) return undefined
    // if (agreement.hasDifference && status.isAppraised) return AppraisalStatusAlert.appraised

    const { inspection } = this
    if (!inspection && !agreement.hasDifference) return AppraisalStatusAlert.appealAnsweredWithAgreement

    return undefined
  }

  get indicator (): Array<any> {
    return [
      this.kmProm,
      this.marketIndicator,
      this.rotation,
      this.appraisalCount,
      this.responseAppealedCount,
    ].filter(_ => _)
  }

  get marketIndicator () {
    const { market } = this
    if (!market) return

    return {
      background: 'purple',
      icon: 'mdi-car-multiple',
      color: undefined,
      tooltip: 'Unidades en mercado',
      value: market.vehiclesNumber,
    }
  }

  get kmProm () {
    // TODO: calculate these values
    // return {
    //  background: 'yellow',
    //  icon: 'mdi-speedometer-slow',
    //  color: undefined,
    // tooltip: '0.7 veces el kilometraje promedio',
    // }

    return undefined
  }

  get rotation () {
    const { deal: { auto: { version: { rotationIndex } } } } = this

    if (!rotationIndex) return

    return {
      background: rotationIndex.color,
      icon: rotationIndex.icon,
      color: undefined,
      tooltip: rotationIndex?.description,
    }
  }

  get appraisalCount () {
    const { deal: { auto: { deals } } } = this

    if (!deals?.length) return

    const appraisalCount = deals.filter(deal => deal.isPurchase)
      .map(deal => deal.appraisalsAggregate?.aggregate?.count).reduce((a, b) => a + b, 0)

    return {
      background: 'primary',
      icon: 'mdi-car',
      color: undefined,
      tooltip: 'N° de tasaciones',
      value: appraisalCount,
    }
  }

  get responseAppealedCount () {
    const { responsesAggregate } = this

    if (!responsesAggregate?.aggregate?.count) return

    return {
      background: 'green',
      icon: 'mdi-gavel',
      color: undefined,
      tooltip: 'N° de veces tasado',
      value: responsesAggregate?.aggregate?.count,
    }
  }

  get executive () {
    const { deal: { lead: { executive } } } = this
    return executive
  }

  get stock () {
    const { deal: { lead: { sale } } } = this
    return sale?.auto
  }

  get appraisalActions () {
    const { agreedAmount, disabledInspection, inspection, deal, status } = this
    const isStatus = status?.isValidCav || status.isApproved
    return [
      {
        icon: 'mdi-car-search',
        tooltip: 'Agendar inspección',
        color: 'purple',
        position: 'center',
        trigger: item => Helpers.linkedModel(item),
        disabled: disabledInspection || !(isStatus && agreedAmount),
        constructor: { name: 'Inspection' },
        id: inspection?.id,
        parent: { id: deal?.lead.id, model: 'Lead' },
      },
    ]
  }

  get appraisalStatus () {
    return this
  }

  get isNotAvailableInspection () {
    const { status } = this

    return status.isNotOffer || status.isPending
  }

  get isInspected () {
    const { inspection } = this

    return Boolean(inspection?.date)
  }

  get disabledInspection () {
    const { isNotAvailableInspection, isInspected } = this

    return isNotAvailableInspection || isInspected
  }

  get agreedAmountDifferent () {
    const { agreedAmount, response: { amount } } = this

    if (agreedAmount !== amount) {
      return amount
    }

    return 0
  }

  get agreementAmountPreInspection () {
    const { agreedAmountDifferent, agreedAmount } = this

    if (agreedAmountDifferent) {
      return agreedAmount
    }

    return 0
  }

  get lastAppraisalResponse () {
    const { responses } = this

    const response = responses?.find(resp => resp.isAppraised)
    if (!response) return { value: 0 }

    return { value: response.amount }
  }

  toString () {
    const { status } = this
    return `${status}`
  }

  get auto () {
    const { deal: { auto } } = this

    return auto
  }

  get kms () {
    const { deal: { autoAttributes } } = this

    const kms = autoAttributes?.find(att => att.slug === 'mileage')

    return fixPrice(kms?.val, false)
  }
}
