import gql from 'graphql-tag'
import { AutoFragment } from '@/graphql/fragments/public'

export const StockViewDetailBase = gql`
  fragment StockViewDetailBase on sales_stock_view {
    expectedPublicationAmount:expected_publication_amount
    appraisalAmount:appraisal_amount
    purchaseAmount:purchase_amount
    enablementMetadata:enablement_metadata
    idDeal:id_deal
    idAuto:id_auto
    idEnablement:id_enablement
    idNegotiation:id_negotiation
  }
`

export const StockViewDetailFragment = gql`
  ${StockViewDetailBase}
  ${AutoFragment}
  fragment StockViewDetail on sales_stock_view {
    ...StockViewDetailBase
    auto{
      ...Auto
    }
    attributes {
      component {
        id
        slug
        name
        favorite
      }
      componentValue: component_value {
        id
        value
      }
      value
    }
  }
`
