import { RouteConfig } from 'vue-router'

import auth from './auth/router'
import datatables from './datatables/router'
import forms from './forms/router'
import vehicles from './vehicles/router'
import errors from './errors/router'
import app from './app/router'

const routes: Array<RouteConfig> = [
  ...auth,
  ...datatables,
  ...forms,
  ...vehicles,
  ...app,
  {
    path: '/home',
    component: () => import('@/layouts/Index.vue'),
    meta: { requiresLogin: true, public: true },
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('./Home.vue'),
        meta: { inherit: true },
      },
      {
        path: '/team',
        name: 'team',
        component: () => import('./ViewUnderConstruction.vue'),
        meta: { inherit: true },
      },
    ],
  },
  {
    path: '*',
    name: 'error',
    component: () => import('@/layouts/errors/Index.vue'),
    children: errors,
  },
]

export default routes
