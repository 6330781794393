import { Actions } from '../../interfaces'
import { Person } from '@/entities/persons'

import fetch from './fetch'
import find from './find'
import create from './create'
import update from './update'
import findLite from './findLite'

export const actions: Actions = {
  fetch,
  find,
  create,
  update,
  findLite,
}

export default {
  Model: { Person },
  actions: { [Person.name]: actions },
}
