import { State as RootState } from '@/store/state'
import { ActionTree, Commit } from 'vuex'
import { Labor } from '@/store/persons/labor/state'
import {
  activityType,
  contractType, findLabor,
  rentType,
} from '@/store/persons/labor/queries/info'
import { fixDate, isEmpty, removeValues } from '@/utils/general'
import { PersonsDetails } from '@/store/persons/state'
import {
  consultRut,
  findEnterprisePerson,
  findPersonShortInfo,
  generateRut,
} from '@/store/persons/queries/personInfo'
import { PersonFormatted } from '@/store/persons/person'
import { EnterpriseFormatted } from '@/store/persons/enterprise'
import { createLaborData, personLaborUpdateStatus, updateLaborData } from '@/store/persons/labor/mutation/labor'
import {
  laborData,
  laborDataIndependentInterface,
  laborDataIndependentInterfaceCreation,
  laborDataNaturalCreation,
} from '@/utils/generalInterface'
import { VueApolloClient } from '@/plugins/apollo'

const getLaborInfoValues = (labor: laborData, idPerson: number): laborDataNaturalCreation => {
  if (labor.id !== -1 && labor.id) {
    return {
      id: labor.id,
      admissionDate: fixDate(labor.dateEmployment),
      executiveComment: labor.comments,
      nameContractType: labor.contractType,
      nameRentType: labor.rentType,
      rent: parseInt(labor.rent),
    }
  }

  return {
    idEmployer: labor.idEmployer,
    idPerson,
    jobTitle: labor.position,
    admissionDate: fixDate(labor.dateEmployment),
    rent: parseInt(labor.rent),
    nameActivityType: 'dependent',
    nameContractType: labor.contractType,
    nameRentType: labor.rentType,
    executiveComment: labor.comments,
  }
}

const getLaborInfoIndependent = (labor: laborDataIndependentInterface, idPerson: number): laborDataIndependentInterfaceCreation => {
  if (labor.id !== -1 && labor.id) {
    return {
      id: labor.id,
      rent: parseInt(labor.rent),
      admissionDate: fixDate(labor.dateEmployment),
      jobKey: labor.siiKey,
      executiveComment: labor.comments,
    }
  }

  return {
    idPerson,
    nameActivityType: 'independent',
    rent: parseInt(labor.rent),
    admissionDate: labor.dateEmployment,
    jobKey: labor.siiKey,
    executiveComment: labor.comments,
  }
}

const getRutInfo = async (apolloClient: VueApolloClient, commit: Commit, rut: string) => {
  try {
    const {
      data: { enterprise },
    } = await apolloClient.query({
      query: findEnterprisePerson,
      variables: {
        rut,
      },
      fetchPolicy: 'network-only',
    })

    if (enterprise.length) {
      const enterpriseFormatted = new EnterpriseFormatted(enterprise[0])
      if (enterpriseFormatted.personType === 'company' || enterpriseFormatted.personType === 'system') {
        commit('setEnterprisePerson', enterpriseFormatted)
      } else {
        commit('setRutErrorMessage', 'Rut pertenece a una persona natural')
      }
    } else {
      try {
        const {
          data: { consultRut: rutInfo },
        } = await apolloClient.query({
          query: consultRut,
          variables: {
            rut,
            personType: 'enterprise',
          },
        })
        commit('setRutInfoLabor', rutInfo)
      } catch (e) {
        // @ts-ignore
        if (e?.message.includes('Invalid RUT')) {
          commit('setRutErrorMessage', 'Rut invalido')
        }
      }
    }
  } catch (e) {
    // @ts-ignore
    if (e?.message.includes('Invalid RUT')) {
      commit('setRutErrorMessage', 'Rut invalido')
    }
  }
}

const createLaborDataInfo = async (apolloClient: VueApolloClient, commit: Commit, laborData: laborDataNaturalCreation): Promise<void> => {
  try {
    const {
      data: { createLaborData: createLabor },
    } = await apolloClient.mutate({
      mutation: createLaborData,
      variables: {
        data: laborData,
      },
    })

    window.dispatchEvent(
      new CustomEvent('notification-message', {
        detail: {
          message: 'Creada exitosamente',
        },
      })
    )

    if (createLabor.exist) {
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          detail: {
            message: 'Referencia previamente creada',
            type: 'error',
          },
        })
      )
    } else {
      commit('setLaborDataCreated', createLabor)
    }

    return createLabor.laborData
  } catch (e) {
    window.dispatchEvent(
      new CustomEvent('notification-message', {
        detail: {
          message: 'Error creando la referencia laboral',
          type: 'error',
        },
      })
    )
  }
}

const updateLaborDataInfo = async (apolloClient: VueApolloClient, commit: Commit, laborData: laborDataNaturalCreation): Promise<void> => {
  try {
    const {
      data: { updatePersonsLaborData: { returning } },
    } = await apolloClient.mutate({
      mutation: updateLaborData,
      variables: {
        ...laborData,
      },
    })

    window.dispatchEvent(
      new CustomEvent('notification-message', {
        detail: {
          message: 'Actualizada exitosamente',
        },
      })
    )

    commit('setLaborDataUpdated', returning[0])
    return returning[0]
  } catch (e) {
    window.dispatchEvent(
      new CustomEvent('notification-message', {
        detail: {
          message: 'Error actualizando la referencia laboral',
          type: 'error',
        },
      })
    )
  }
}

function buildVariables (rut: string, phone: string): PersonsDetails {
  const variables: PersonsDetails = {}

  if (!isEmpty(phone)) {
    variables.phone = phone
  } else if (!isEmpty(rut)) {
    variables.rut = removeValues(rut)
  }

  return variables
}

async function getPerson (apolloClient: VueApolloClient, variables: PersonsDetails) {
  const {
    data: { person },
  } = await apolloClient.query({
    query: findPersonShortInfo,
    variables,
    fetchPolicy: 'network-only',
  })

  return person
}

function handlePerson (commit: Commit, person: any, id: string) {
  if (person.id === id) {
    sendNotification('No puedes agregar como empleador al mismo cliente si la actividad es dependiente', 'error')
    commit('setFoundPersonLabor', null)
    return
  }

  const personFormatted = new PersonFormatted(person)
  if (personFormatted.personType === 'natural_person') {
    commit('setFoundPersonLabor', personFormatted)
  } else {
    commit('setRutErrorMessage', 'Rut pertenece a una empresa')
  }
}

async function handleError (commit: Commit, variables: PersonsDetails, apolloClient: VueApolloClient) {
  if (!isEmpty(variables.rut)) {
    delete variables.phone
    await handleRutError(commit, variables, apolloClient)
  }
}

async function handleRutError (commit: Commit, variables: PersonsDetails, apolloClient: VueApolloClient) {
  try {
    const {
      data: { consultRut: rutInfo },
    } = await apolloClient.query({
      query: consultRut,
      variables,
    })
    commit('setRutInfoLabor', rutInfo)
  } catch (e) {
    // @ts-ignore
    if (e?.message.includes('Invalid RUT')) {
      commit('setRutErrorMessage', 'Rut invalido')
    }
  }
}

function sendNotification (message: string, type: string) {
  window.dispatchEvent(
    new CustomEvent('notification-message', {
      detail: {
        type,
        message,
      },
    })
  )
}

export const actions: ActionTree<Labor, RootState> = {
  getLaborInfo: async ({
    commit,
    rootState: { apolloClient },
  }): Promise<void> => {
    if (!apolloClient) return
    const {
      data: { activity },
    } = await apolloClient.query({
      query: activityType,
    })

    commit('setActivity', activity)

    const {
      data: { rent },
    } = await apolloClient.query({
      query: rentType,
    })

    commit('setRent', rent)

    const {
      data: { contract },
    } = await apolloClient.query({
      query: contractType,
    })

    commit('setContract', contract)
  },
  findEnterprise: async (
    { commit, rootState: { apolloClient } },
    rut
  ): Promise<void> => {
    if (!apolloClient || isEmpty(rut)) return

    if (rut.includes('-')) {
      return getRutInfo(apolloClient, commit, rut)
    }

    try {
      const { data: { generateRut: rutFormatted } } = await apolloClient.query({
        query: generateRut,
        variables: {
          rut,
        },
      })
      await getRutInfo(apolloClient, commit, rutFormatted)
    } catch (e) {
      // @ts-ignore
      if (e?.message.includes('Invalid RUT')) {
        commit('setRutErrorMessage', 'Rut invalido')
      }
    }
  },
  findNaturalPerson: async (
    { commit, state, rootState: { apolloClient } },
    { phone, rut, id }
  ): Promise<void> => {
    if (!apolloClient || (isEmpty(phone) && isEmpty(rut))) {
      return
    }

    const variables = buildVariables(rut, phone)

    try {
      const person = await getPerson(apolloClient, variables)
      handlePerson(commit, person, id)
    } catch (e) {
      // @ts-ignore
      await handleError(commit, variables, apolloClient)
    }
  },
  sendLaborIndependent: async ({
    commit,
    rootState: { apolloClient },
  }, {
    data,
    idPerson,
  }: { data: laborDataIndependentInterface, idPerson: number }): Promise<void> => {
    if (!apolloClient || !idPerson || !data) return
    let resp
    const laborData = getLaborInfoIndependent(data, idPerson)
    if (data.id !== -1 && data.id) {
      resp = await updateLaborDataInfo(apolloClient, commit, laborData)
    } else {
      resp = await createLaborDataInfo(apolloClient, commit, laborData)
    }
    return resp
  },
  sendLaborDependent: async ({
    commit,
    rootState: { apolloClient },
  }, { data, idPerson }: { data: laborData, idPerson: number }): Promise<void> => {
    if (!apolloClient) return

    const laborData = getLaborInfoValues(data, idPerson)
    let resp
    if (data.id !== -1 && data.id) {
      resp = await updateLaborDataInfo(apolloClient, commit, laborData)
    } else {
      resp = await createLaborDataInfo(apolloClient, commit, laborData)
    }

    return resp
  },
  laborUpdateStatus: async ({
    commit,
    rootState: { apolloClient },
  }, id): Promise<void> => {
    if (!id || !apolloClient) return

    try {
      await apolloClient.mutate({
        mutation: personLaborUpdateStatus,
        variables: {
          id,
        },
      })

      window.dispatchEvent(
        new CustomEvent('notification-message', {
          detail: {
            message: 'Referencia laboral eliminada exitosamente',
          },
        })
      )
    } catch (e) {
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          detail: {
            message: 'Error eliminando la referencia laboral',
            type: 'error',
          },
        })
      )
    }
  },
  cleanInfoData: ({ commit }): void => {
    commit('setFoundPersonLabor', undefined)
    commit('setEnterprisePerson', undefined)
    commit('setRutInfoLabor', undefined)
  },
  findLaborData: async ({
    commit,
    rootState: { apolloClient },
  }, id): Promise<void> => {
    if (!id || !apolloClient) return
    try {
      const {
        data: { info },
      } = await apolloClient.query({
        query: findLabor,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
      })

      commit('setLaborData', info)
    } catch (e) {
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          detail: {
            message: 'Error obteniendo la referencia laboral',
            type: 'error',
          },
        })
      )
    }
  },
}
