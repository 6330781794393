import { Transform, Type } from 'class-transformer'
import { Entity } from '..'

import { Evaluation } from './Evaluation'
import { Quota } from './Quota'
import { Variant } from '../settings'
import { fixPrice } from '@/utils/general'
import dayjs, { Dayjs } from 'dayjs'

export class Offer extends Entity {
  @Type(() => Evaluation)
  evaluation: Evaluation;

  @Type(() => Variant)
  variant: Variant;

  @Type(() => Quota)
  quotas: Quota[];

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  startPayment: Dayjs;

  rate: number;
  fee: number;
  accepted: boolean;
  comment?: string;
  coverage?: number
  insurance: null

  get description () {
    const { variant, fee, quotas, startPayment } = this
    if (!variant) return undefined
    const quota = quotas.find(quota => quota.characteristic.id === variant.isRegular.id)

    return [
      variant.agreementProduct.product.description,
      `${variant.description} ${fixPrice(quota?.amount)}`,
      `Comision ${fixPrice(fee)}`,
      `1ª Vence ${startPayment.format('DD/MM/YYYY')}`,
    ].join(' / ')
  }

  get shortDescription () {
    const { variant, quotas } = this
    if (!variant) return undefined
    const quota = quotas.find(quota => quota.characteristic.id === variant.isRegular.id)

    return [
      variant.agreementProduct?.product?.description,
      variant?.description, fixPrice(quota?.amount),
    ].join(' / ')
  }

  get warrantyDescription () {
    const { variant, coverage } = this

    const description = variant?.description
    const amount = coverage ? (coverage / 1000000) : 0
    const company = variant?.agreementProduct?.agreement?.person?.alias

    const amountDescription = amount ? `${amount}M` : 'Gratis'

    return `${description} ${amountDescription} ${company}`
  }
}
