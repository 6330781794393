import gql from 'graphql-tag'
import { ActivityFragment, LeadFragment } from '@/graphql/fragments/crm'

export const query = gql`
  ${LeadFragment}
  ${ActivityFragment}
  query findLeadActivity($filter: crm_lead_activity_bool_exp) {
    records: crm_lead_activity(where: $filter) {
      ...Activity
    }
  }
`

export default query
