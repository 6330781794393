import { Actions } from '../interfaces'

import closingReasonType from './closingReasonType'
import process from './process'
import closingReason from './closingReason'
import ProcessStatus from './processStatus'
import businessApproach from './businessApproach'
import productApproach from './productApproach'
import agreementProduct from './agreementProduct'
import agreementType from './agreementType'
import variant from './variant'
import supportDocumentType from './supportDocumentType'
import transferType from './transferType'
import processExpense from './processExpense'
import validationType from './validationType'
import deductibleType from './deductibleType'
import currency from './currency'
import exchangeRate from './exchangeRate'
import expense from './expense'

export const Models = {
  ...closingReasonType.Model,
  ...process.Model,
  ...closingReason.Model,
  ...ProcessStatus.Model,
  ...agreementProduct.Model,
  ...agreementType.Model,
  ...productApproach.Model,
  ...variant.Model,
  ...businessApproach.Model,
  ...supportDocumentType.Model,
  ...transferType.Model,
  ...processExpense.Model,
  ...validationType.Model,
  ...deductibleType.Model,
  ...currency.Model,
  ...exchangeRate.Model,
  ...expense.Model,
}

export const actions: Record<string, Actions> = {
  ...closingReasonType.actions,
  ...process.actions,
  ...closingReason.actions,
  ...ProcessStatus.actions,
  ...businessApproach.actions,
  ...productApproach.actions,
  ...variant.actions,
  ...agreementProduct.actions,
  ...agreementType.actions,
  ...supportDocumentType.actions,
  ...transferType.actions,
  ...processExpense.actions,
  ...validationType.actions,
  ...deductibleType.actions,
  ...currency.actions,
  ...exchangeRate.actions,
  ...expense.actions,
}

export const schema = { Models, actions }
export default schema
