import firebase from 'firebase/app'
import 'firebase/auth'
import { User as FirebaseUser } from '@firebase/auth-types'

import { ActionTree } from 'vuex'
import store from '@/store'
import { State as RootState } from '@/store/state'
import { User } from './state'
import { onLogin, onLogout } from '@/plugins/apollo'
import { GENIO_RELOADING } from '@/utils/general'
import { getUserId, getUserRoles } from '@/store/user/queries'
import { createUserByEmail, modifyAllowedRoles, modifyDefaultRole } from '@/graphql/customQueries/hr/employee/employee'

const actions: ActionTree<User, RootState> = {
  handleUserChange: async ({
    commit,
    dispatch,
    rootState: { apolloClient },
  }, user: FirebaseUser): Promise<string | undefined> => {
    if (!apolloClient) return
    if (!user) {
      await onLogout(apolloClient)
      commit('logout')

      return 'logout'
    }

    try {
      const {
        token,
        claims: {
          'https://hasura.io/jwt/claims': { 'x-hasura-default-role': roleName },
        },
        expirationTime,
      } = await user.getIdTokenResult()

      await onLogin(apolloClient, token)
      const expiration = new Date(expirationTime)
      if (expiration.getTime() - Date.now() > 10 * 60 * 1000) {
        await dispatch('refreshToken', true)
      }

      const id = await getUserId(apolloClient, user)

      if (!id) {
        await store.dispatch('notification/loadNotification', {
          message: `Contacta al soporte técnico, tu usuario ${user.email} no esta registrado`,
          notificationType: 'error',
        })
        return
      }
      const roles = await getUserRoles(apolloClient, id)

      commit('login', { user, roles, token, id, roleName })

      return 'login'
    } catch (e: any) {
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          detail: { message: e.message, type: 'error' },
        }),
      )
    }
  },
  logout: () => firebase.auth().signOut(),
  async refreshToken ({ commit }, forceRefresh = true) {
    const user = await firebase.auth().currentUser
    if (user) {
      const newToken = await user.getIdToken(forceRefresh)
      localStorage.setItem(GENIO_RELOADING, 'false')
      commit('updateJwt', newToken)
    }
  },
  // Acción para registrar un nuevo usuario
  async registerUser ({ rootState: { apolloClient } }, userData) {
    if (!apolloClient) return

    try {
      const { displayName, email, role, password } = userData
      await apolloClient.mutate({
        mutation: createUserByEmail,
        variables: {
          input: {
            displayName,
            email,
            role,
            password,
          },
        },
      })
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          detail: { message: 'Usuario registrado con éxito', type: 'success' },
        }),
      )
    } catch (e: any) {
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          detail: { message: e.message, type: 'error' },
        }),
      )
    }
  },

  // Acción para modificar el rol por defecto
  async changeDefaultRole ({ rootState: { apolloClient } }, { email, defaultRole }) {
    if (!apolloClient) return

    try {
      await apolloClient.mutate({
        mutation: modifyDefaultRole,
        variables: {
          email,
          key: 'x-hasura-default-role',
          value: defaultRole,
        },
      })
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          detail: { message: 'Rol por defecto modificado con éxito', type: 'success' },
        }),
      )
    } catch (e: any) {
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          detail: { message: e.message, type: 'error' },
        }),
      )
    }
  },

  // Acción para modificar los roles permitidos
  async changeAllowedRoles ({ rootState: { apolloClient } }, { email, allowedRoles }) {
    if (!apolloClient) return

    try {
      await apolloClient.mutate({
        mutation: modifyAllowedRoles,
        variables: {
          email,
          key: 'x-hasura-allowed-roles',
          value: JSON.stringify(allowedRoles),
        },
      })
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          detail: { message: 'Roles permitidos modificados con éxito', type: 'success' },
        }),
      )
    } catch (e: any) {
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          detail: { message: e.message, type: 'error' },
        }),
      )
    }
  },
}

export default actions
